const markerConstants = {
  MARKER_SMALL: '4rem',
  MARKER_BIG: '8rem',
  MARKER_SMALL_CORR: 21,
  MARKER_BIG_CORR: 40,
  MARKER_BIG_CORR_REL: 1,
  MARKER_SMALL_CORR_REL: 1,

  UPLOADED_IMAGE_WIDTH: '1000px',
};

module.exports = markerConstants;
