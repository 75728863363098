import axios from 'axios';
import { showAlert } from './alerts';
import { translations } from './translations';

export const sendTask = async (
  customer = '',
  carModel,
  dents,
  images,
  specialCase,
  remark,
  defaultLang,
) => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/api/v1/tasks/sendTask',
      data: { user: customer, carModel, dents, images, specialCase, remark },
    });
    if (res.data.status === 'success') {
      alert(translations[defaultLang]['taskSent']);

      window.setTimeout(() => {
        window.scrollTo(0, 0);
        location.reload();
      }, 50);
    }
  } catch (err) {
    console.error(err);
    //TODO: change error message back to send failed
    showAlert('error', translations[defaultLang]['taskSendFailed']);
    // showAlert('error', err);
  }
};
