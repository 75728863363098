export const translations = {
  en: {
    welcome: 'Welcome to DentMarker!',
    choosePhotos: 'Please choose photos to upload:',
    needHelp: 'Need help with the app? Press here',
    chooseSide: 'Choose side',
    paintDamaged: 'Paint damaged',
    specialCase: 'Special case',
    clearLastMark: 'Clear Last Mark',
    deleteImage: 'Delete Image',
    clearAllMarks: 'Clear All Marks',
    year: 'Year',
    older: 'Older',
    sendTask: 'Send Task',
    myProfile: 'My profile',
    sendNewTask: 'Send new task',
    myTasks: 'My tasks',
    help: 'Help',
    logOut: 'Log out',
    removeAllMarks: 'Remove all markers?',
    deleteImage: 'Delete this image?',
    noFilesChosen: 'No photos chosen',
    noDentsMarked: 'You have not marked any dent yet',
    modelNameMinLength: 'Model name must have at least 5 characters',
    chooseModelYear: 'Please choose model year',
    noteMaxLength: 'Note must not exceed 150 characters',
    addShortDescription: 'Please add short description',
    costPositiveNumber: 'Cost must be a positive number',
    costMaxValue: 'Cost must not exceed 10,000',
    taskSent: 'Your task is sent successfully! We will contact you soon.',
    taskSendFailed: 'Sending task failed',
    reportFailed: 'Error making the report',
    changeAccountSettings: 'Change your account settings',
    companyName: 'Company / Name',
    saveChanges: 'Save changes',
    changePassword: 'Change your password',
    currentPassword: 'Current password',
    newPassword: 'New password',
    confirmNewPassword: 'Confirm new password',
    changePasswordButton: 'Change password',
    language: 'Language',
    taskCreated: 'Task created:',
    taskStatus: 'Task status:',
    inProgress: 'In Progress',
    completeNotPaid: 'Complete/Not paid',
    paid: 'Paid',
    totalCost: 'Total cost:',
    notes: 'Notes:',
    backToTasks: 'Back to tasks',
    saveChangesTask: 'Save changes',
  },
  nl: {
    welcome: 'Welkom bij DentMarker!',
    choosePhotos: `Kies foto's om te uploaden:`,
    needHelp: 'Hulp nodig met de app? Druk hier',
    chooseSide: 'Kies kant',
    paintDamaged: 'Lak beschadigd',
    specialCase: 'Speciaal geval',
    clearLastMark: 'Laatste markering wissen',
    deleteImage: 'Afbeelding verwijderen',
    clearAllMarks: 'Alle markeringen wissen',
    year: 'Jaar',
    older: 'Ouder',
    sendTask: 'Taak verzenden',
    myProfile: 'Mijn profiel',
    sendNewTask: 'Nieuwe taak',
    myTasks: 'Mijn taken',
    help: 'Hulp',
    logOut: 'Uitloggen',
    removeAllMarks: 'Alle markeringen verwijderen?',
    deleteImage: 'Deze afbeelding verwijderen?',
    noFilesChosen: `Geen foto's gekozen`,
    noDentsMarked: 'Je hebt nog geen deuk gemarkeerd',
    modelNameMinLength: 'Modelnaam moet minstens 5 tekens bevatten',
    chooseModelYear: 'Kies het modeljaar',
    noteMaxLength: 'Notitie mag niet meer dan 150 tekens bevatten',
    addShortDescription: 'Voeg een korte beschrijving toe',
    costPositiveNumber: 'Kosten moeten een positief getal zijn',
    costMaxValue: 'Kosten mogen niet hoger zijn dan 10.000',
    taskSent:
      'Je taak is succesvol verzonden! We nemen binnenkort contact met je op.',
    taskSendFailed: 'Verzenden van taak mislukt',
    reportFailed: 'Het maken van het rapport mislukt',
    changeAccountSettings: 'Wijzig je accountinstellingen',
    companyName: 'Bedrijf / Naam',
    saveChanges: 'Wijzigingen opslaan',
    changePassword: 'Wijzig je wachtwoord',
    currentPassword: 'Huidig wachtwoord',
    newPassword: 'Nieuw wachtwoord',
    confirmNewPassword: 'Bevestig nieuw wachtwoord',
    changePasswordButton: 'Wachtwoord wijzigen',
    language: 'Taal',
    taskCreated: 'Taak verzonden op:',
    taskStatus: 'Taakstatus:',
    inProgress: 'In behandeling',
    completeNotPaid: 'Voltooid/Niet betaald',
    paid: 'Betaald',
    totalCost: 'Totale kosten:',
    notes: 'Opmerkingen:',
    backToTasks: 'Terug naar taken',
    saveChangesTask: 'Wijzigingen opslaan',
  },
};
